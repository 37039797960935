import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <h1 style={{ marginTop: "75px" }}>404</h1>
  </Layout>
)

export default NotFoundPage
